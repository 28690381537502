

// import useStyles from 'isomorphic-style-loader/useStyles';
// import React from 'react';
// import s from './Footer.css';
// import Link from 'components/Link/Link';

// export default function Footer() {
//   useStyles(s);

//   return (
//     <div className={s.root}>
//       <div className={s.container}>
//         <span className={s.text}>© Your Company</span>
//         <span className={s.spacer}>·</span>
//         <Link className={s.link} to="/">
//           Home
//         </Link>
//         <span className={s.spacer}>·</span>
//         <Link className={s.link} to="/admin">
//           Admin
//         </Link>
//         <span className={s.spacer}>·</span>
//         <Link className={s.link} to="/privacy">
//           Privacy
//         </Link>
//         <span className={s.spacer}>·</span>
//         <Link className={s.link} to="/not-found">
//           Not Found
//         </Link>
//       </div>
//     </div>
//   );
// }

/*!

=========================================================
* Paper Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://infowin.com.tw/product/paper-dashboard-pro-react
* Copyright 2020 Infowin Technology Co., Ltd. (https://infowin.com.tw)

* Coded by Infowin Technology Co., Ltd.

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React from "react";
import { Container, Row } from "reactstrap";
// used for making the prop types of this component
import PropTypes from "prop-types";

class Footer extends React.Component {
  render() {
    return (
      <footer
        className={"footer" + (this.props.default ? " footer-default" : "")}
      >
        <Container fluid={this.props.fluid ? true : false}>
          <Row>
            <nav className="footer-nav">
              <ul>
                <li>
                  <a href="https://infowin.com.tw">Infowin Technology</a>
                </li>
                <li>
                  <a href="https://blog.infowin.com.tw">Blog</a>
                </li>
                <li>
                  <a
                    href="https://infowin.com.tw/license"
                    target="_blank"
                  >
                    Licenses
                  </a>
                </li>
              </ul>
            </nav>
            <div className="credits ml-auto">
              <span className="copyright">
                &copy; {1900 + new Date().getYear()}, made with{" "}
                <i className="fa fa-heart heart" /> by Infowin Technology Co., Ltd.
              </span>
            </div>
          </Row>
        </Container>
      </footer>
    );
  }
}

Footer.propTypes = {
  default: PropTypes.bool,
  fluid: PropTypes.bool,
};

export default Footer;
