
import React, { Component } from 'react'
import classnames from "classnames";
import { Image } from 'semantic-ui-react';
import { Collapse, NavbarBrand, Navbar, NavItem, Nav, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Container, } from "reactstrap";
import { FormattedMessage, } from 'react-intl';
import Link from 'components/Link/Link';

import ChgCompany from 'components/admin/ChgCompany';
import ChgLocale from 'components/admin/ChgLocale';

class AuthNavbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapseOpen: false,
      color: "navbar-transparent",
    };
  }

  async componentDidMount() {
    window.addEventListener("resize", this.updateColor);
  }

  // this function opens and closes the collapse on small devices
  // it also adds navbar-transparent class to the navbar when closed
  // ad bg-white when opened
  toggleCollapse = () => {
    let newState = {
      collapseOpen: !this.state.collapseOpen
    };
    if (!this.state.collapseOpen) {
      newState["color"] = "bg-white";
    } else {
      newState["color"] = "navbar-transparent";
    }
    this.setState(newState);
  };

  render() {
    const { companyObj } = this.props; //companyObj
    const { themeConf = {} } = companyObj;
    const { img1File = {} } = themeConf
    // {companyObj[dbLang]}
    // console.log(companyObj)
    return (<>
      <Navbar className={classnames("navbar-absolute fixed-top", this.state.color)} expand="lg" >
        <Container>
          <div className="navbar-wrapper">
            <NavbarBrand href="/">
              {img1File && img1File.url ? <Image size="small" src={img1File.url} /> : (companyObj && companyObj.name) || ''}
            </NavbarBrand>
          </div>
          <button
            aria-controls="navigation-index"
            aria-expanded={false}
            aria-label="Toggle navigation"
            className="navbar-toggler"
            data-toggle="collapse"
            type="button"
            onClick={this.toggleCollapse}
          >
            <span className="navbar-toggler-bar navbar-kebab" />
            <span className="navbar-toggler-bar navbar-kebab" />
            <span className="navbar-toggler-bar navbar-kebab" />
          </button>
          <Collapse isOpen={!!this.state.collapseOpen} className="justify-content-end" navbar>
            <Nav navbar>
              {companyObj.useOfficial ? <NavItem>
                <a href="/"
                  // replace={true}
                  className="nav-link">
                  <i className="nc-icon nc-planet" />
                  <FormattedMessage
                    id="Official Website"
                    description=""
                    defaultMessage="Official Website"
                  />
                </a>
              </NavItem> : null}
              <NavItem>
                <Link to="/register" className="nav-link">
                  <i className="nc-icon nc-book-bookmark" />
                  <FormattedMessage
                    id="Register"
                    description=""
                    defaultMessage="Register"
                  />
                </Link>
              </NavItem>
              <NavItem>
                <Link to="/login" className="nav-link">
                  <i className="nc-icon nc-tap-01" />
                  <FormattedMessage
                    id="Login"
                    description=""
                    defaultMessage="Login"
                  />
                </Link>
              </NavItem>
              <NavItem>
                <Link to="/resetpassword" className="nav-link">
                  <i className="nc-icon nc-key-25" />
                  <FormattedMessage
                    id="Reset Password"
                    description=""
                    defaultMessage="Reset Password"
                  />
                </Link>
              </NavItem>
              {/* <NavItem>
                <NavLink to="/admin/dashboard" className="nav-link">
                  <i className="nc-icon nc-layout-11" />
                  {intl.get('Dashboard')}
                </NavLink>
              </NavItem>

              <NavItem>
                <NavLink to="/admin/user-profile" className="nav-link">
                  <i className="nc-icon nc-satisfied" />
                  {intl.get('User')}
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink to="/auth/lock-screen" className="nav-link">
                  <i className="nc-icon nc-key-25" />
                  {intl.get('Lock')}
                </NavLink>
              </NavItem> */}
              {/* {companyObj.isMultiLang ? <  {...this.props} /> : null} */}
              <ChgLocale />
              <ChgCompany />
            </Nav>
          </Collapse>
        </Container>
      </Navbar></>
    );
  }
}

// const mapState = state => ({
//   user: state.user,
//   availableLocales: state.runtime.availableLocales,
//   currentLocale: state.intl.locale,
// });

// const mapDispatch = {
//   // setCompany,
//   // logout,
//   setLocale,
// };

// export default connect(mapState, mapDispatch)(AuthNavbar);
export default AuthNavbar;
