

// import useStyles from 'isomorphic-style-loader/useStyles';

// import React from 'react';
// import PropTypes from 'prop-types';

// // external-global styles must be imported in your JS.
// import normalizeCss from 'normalize.css';
// import s from './AuthLayout.css';
// import AuthHeader from '../AuthHeader/AuthHeader';
// import AdminFeedback from '../AdminFeedback/AdminFeedback';
// import AdminFooter from '../AdminFooter/AdminFooter';

// export default function AuthLayout2({ children }) {
//   useStyles(s, normalizeCss);
//   return (
//     <>
//       <AuthHeader />
//       {children}
//       <AdminFeedback />
//       <AdminFooter />
//     </>
//   );
// }

// AuthLayout2.propTypes = {
//   children: PropTypes.node.isRequired,
// };

/*!

=========================================================
* Paper Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://infowin.com.tw/product/paper-dashboard-pro-react
* Copyright 2020 Infowin Technology Co., Ltd. (https://infowin.com.tw)

* Coded by Infowin Technology Co., Ltd.

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react';
// javascript plugin used to create scrollbars on windows
// import PerfectScrollbar from 'perfect-scrollbar';
import { Route, Switch } from 'react-router-dom';
import withStyles from 'isomorphic-style-loader/withStyles';

import Footer from 'components/Footer/Footer.js';
import AuthNavbar from '../AuthNavbar/AuthNavbar.jsx';

// import routes from "routes.js";

import a from 'bootstrap/dist/css/bootstrap.min.css';
// import d from 'perfect-scrollbar/css/perfect-scrollbar.css';
import b from '../../../../public/assets_admin/css/paper-dashboard.css';
import c from '../../../../public/assets_admin/demo/demo.css';

let ps;

class AuthLayout2 extends React.Component {
  componentDidMount() {
    // if (navigator.platform.indexOf('Win') > -1) {
    //   ps = new PerfectScrollbar(this.refs.fullPages);
    // }
  }

  componentWillUnmount() {
    // if (navigator.platform.indexOf('Win') > -1) {
    //   ps.destroy();
    // }
  }

  getRoutes = routes => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return this.getRoutes(prop.views);
      }
      if (prop.layout === '/auth') {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      }
      return null;
    });
  };

  render() {
    const { children } = this.props;
    return (<>
      <AuthNavbar {...this.props} />
      <div className="wrapper wrapper-full-page" ref="fullPages">
        <div className="full-page section-image">
          {/* <Switch>{this.getRoutes(routes)}</Switch> */}
          {children}
          <Footer fluid />
        </div>
      </div>
    </>);
  }
}

// export default AuthLayout2;
export default withStyles(a, b, c)(AuthLayout2);
