
import React from 'react';

import ApplicationContext from 'components/ApplicationContext';
import AuthLayout2 from './AuthLayout2';

// import withStyles from 'isomorphic-style-loader/withStyles'
// import useStyles from 'isomorphic-style-loader/useStyles';

// import a from "bootstrap/dist/css/bootstrap.css";
// import b from "../../../public/assets_admin/css/paper-dashboard.css";
// import c from "../../../public/assets_admin/demo/demo.css";
// import d from "perfect-scrollbar/css/perfect-scrollbar.css";

// import StyleContext from 'isomorphic-style-loader/StyleContext'
// import a from "bootstrap/dist/css/bootstrap.css";
// // import b from "assets/scss/paper-dashboard.scss?v=1.2.0";
// import b from "assets/css/paper-dashboard.min.css";
// // import c from "assets/demo/demo.css";
// import c from "assets/css/demo-dashboard.css";
// import d from "perfect-scrollbar/css/perfect-scrollbar.css";
// import React from 'react';
// import PropTypes from 'prop-types';

// // external-global styles must be imported in your JS.
// import normalizeCss from 'normalize.css';
// import s from './AdminLayout.css';
// import AdminHeader from '../AdminHeader/AdminHeader';
// import AdminFeedback from '../AdminFeedback/AdminFeedback';
// import AdminFooter from '../AdminFooter/AdminFooter';

// export default function Layout({ children }) {
//   useStyles(s, normalizeCss);
//   return (
//     <>
//       <AdminHeader />
//       {children}
//       <AdminFeedback />
//       <AdminFooter />
//     </>
//   );
// }

// Layout.propTypes = {
//   children: PropTypes.node.isRequired,
// };

function AuthLayout(props) {
  // useStyles(a, b, c, d);
  // console.log(props);
  const value = React.useContext(ApplicationContext);
  const { pathname } = value.context;

  return <AuthLayout2 {...props} pathname={pathname} />;
}

// export default AdminLayout;
export default AuthLayout;
